<template>
    <div class="card card-body mt-4">
      <h3>Add New Vertical Feed</h3>
      <form @submit.prevent="submit">
        <div class="form-group mt-3">
          <label>Title</label>
          <input v-model="form.title" class="form-control" required />
        </div>

        <div class="form-group">
          <label>Description</label>
          <input v-model="form.description" class="form-control" required />
        </div>

        <div class="form-group">
          <label>Authority</label>
          <input v-model="form.authority" class="form-control" type="number" required />
        </div>

        <div class="form-group">
          <label>Quality</label>
          <input v-model="form.quality" class="form-control" type="number" required />
        </div>

        <div class="form-group">
          <label>Type</label>
          <select v-model="form.typeId" class="form-control" required @change="updateSelectedType">
            <option value="">Select Type</option>
            <option v-for="fType in feedTypeList" 
              v-bind:selected="fType.typeId == form.typeId"
              v-bind:value="fType.typeId"            
            >{{ fType.title }}</option>
          </select>
        </div>
  
        <div class="form-group">
          <label>URL</label>
          <input v-model="form.url" class="form-control" required />
        </div>
        
        <div class="form-group">
          <label>Is Active</label>
          <input v-model="form.is_active" class="mx-2" type="checkbox" />
        </div>
  
        <button type="button" class="btn btn-danger mt-3" @click="cancelBtn">
          Cancel
        </button>

        <button type="submit" class="btn btn-primary mx-2 mt-3">
          Insert
        </button>
      </form>
    </div>
  </template>
  
  <script>
  import { reactive, computed } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import { createVerticalFeed, useLoadFeedTypes } from '@/firebase'
  
  export default {
    setup() {
      const router = useRouter()
      const route = useRoute()
      
      const verticalId = computed(() => route.params.verticalId)
  
      console.log(verticalId.value)

      const form = reactive({ title: '', type: '', url: '', description: '', authority: '', quality: '', typeId: '', is_active: false })
        
      const submit = async () => {
        // get title for selected option
        let allTypes = await useLoadFeedTypes()
        debugger
        const selectedType = allTypes.find(fType => fType.typeId == form.typeId)
        form.typeId = selectedType.typeId
        form.type = selectedType.title
        await createVerticalFeed(verticalId.value, {...form})
        router.push('/vertical/'+verticalId.value+'/feed/')
        form.description = ''
        form.title = ''
        form.type = ''
        form.url = ''
        form.authority = ''
        form.quality = ''
        form.typeId = ''
        form.is_active = false
      }

      const cancelBtn = () => {
        router.push('/vertical/'+verticalId.value+'/feed/')
      }
  
      return { form, submit, cancelBtn }
    },
    data() {
      return {
        isLoading: false,
        feedTypeList: []
      }
    },
    methods: {
      getFeedTypeList() {
        this.loading = true
        useLoadFeedTypes().then(response => {
          this.loading = false
          this.feedTypeList = response
        })
        .catch(error => {
          this.loading = false
          console.log(error)
        })
      }
    },
    created() {
      this.getFeedTypeList()
    }
  }
  </script>
<template>
    <div class="card card-body mt-4">
      <h3>Edit Self</h3>
      <form @submit.prevent="update">
        <div class="form-group">
          <label>Name</label>
          <input v-model="form.name" class="form-control" required />
        </div>
  
        <div class="form-group mt-3">
          <label>Email</label>
          <input
            v-model="form.email"
            class="form-control"
            type="email"
            required
          />
        </div>

        <div class="form-group mt-3">
          <label>Password</label>
          <input
            v-model="form.password"
            class="form-control"
            type="password"
          />
        </div>
  
        <button type="button" class="btn btn-danger mt-3" @click="cancelBtn">
          Cancel
        </button>
  
        <button type="submit" class="btn btn-primary mx-2 mt-3">
          Update
        </button>
      </form>
    </div>
  </template>
  
  <script>
  import { reactive, computed, onMounted } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import { getAdminFromSession, updateSelf } from '@/firebase'
  
  export default {
    setup() {
      const router = useRouter()
      const route = useRoute()
      
  
      const form = reactive({ name: '', email: '' })
      onMounted(async () => {

        const user = await getAdminFromSession()

        form.name = user.name
        form.email = user.email
        form.userId = user.id
      })
  
      const update = async () => {
        console.log("asd")
        await updateSelf(form.userId, form.name, form.email, form.password)
        router.push('/')
        form.username = ''
        form.email = ''
        form.password = ''
      }

      const cancelBtn = () => {
        router.push('/')
      }
  
      return { form, update, cancelBtn }
    }
  }
  </script>
<template>
    <div class="card card-body mt-4">
      <h3>Edit Vertical Feed</h3>
      <form @submit.prevent="update">
        
        <div class="form-group mt-3">
          <label>Title</label>
          <input v-model="form.title" class="form-control" required />
        </div>

        <div class="form-group">
          <label>Description</label>
          <input v-model="form.description" class="form-control" required />
        </div>

        <div class="form-group">
          <label>Authority</label>
          <input v-model="form.authority" class="form-control" type="number" required />
        </div>

        <div class="form-group">
          <label>Quality</label>
          <input v-model="form.quality" class="form-control" type="number" required />
        </div>

        <div class="form-group">
          <label>Type</label>
          <select v-model="form.typeId" class="form-control" required>
            <option value="">Select Type</option>
            <option v-for="fType in feedTypeList" :key="fType.typeId" :value="fType.typeId">
              {{ fType.title }}
            </option>
          </select>

        </div>

        <div class="form-group">
          <label>URL</label>
          <input v-model="form.url" class="form-control" required />
        </div>
        
        <div class="form-group">
          <label>Is Active</label>
          <input v-model="form.is_active" class="mx-2" type="checkbox" />
        </div>
  
        <button type="button" class="btn btn-danger mt-3" @click="cancelBtn">
          Cancel
        </button>
        
        <button type="submit" class="btn btn-primary mx-2 mt-3">
          Update
        </button>
      </form>
    </div>
  </template>
  
  <script>
  import { reactive, computed, onMounted } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import { getVerticalFeed, updateVerticalFeed, useLoadFeedTypes } from '@/firebase'
  
  export default {
    setup() {
      const router = useRouter()
      const route = useRoute()
      const verticalId = computed(() => route.params.verticalId)
      const verticalFeedId = computed(() => route.params.id)
      
      console.log(verticalId.value)
      console.log(verticalFeedId.value)

      const form = reactive({ title: '', description: '', authority: '', quality: '', type: '', typeId: '', url: '', is_active: false })
      onMounted(async () => {
        const verticalFeed = await getVerticalFeed(verticalId.value, verticalFeedId.value)

        console.log(verticalFeed)
        debugger
        form.title = verticalFeed.title
        form.description = verticalFeed.description
        form.authority = verticalFeed.authority
        form.quality = verticalFeed.quality
        form.type = verticalFeed.type
        form.typeId = verticalFeed.typeId
        form.url = verticalFeed.url
        form.is_active = verticalFeed.is_active
      })
  
      const update = async () => {
        let allTypes = await useLoadFeedTypes()
        debugger
        const selectedType = allTypes.find(fType => fType.typeId == form.typeId)
        form.typeId = selectedType.typeId
        form.type = selectedType.title

        await updateVerticalFeed(verticalId.value, verticalFeedId.value, { ...form })
        router.push('/vertical/'+verticalId.value+'/feed/')
        form.title = ''
        form.type = ''
        form.typeId = ''
        form.url = ''
        form.description = ''
        form.authority = ''
        form.quality = ''
        form.is_active = false
      }

      const cancelBtn = () => {
        router.push('/vertical/'+verticalId.value+'/feed/')
      }
  
      return { form, update, cancelBtn}
    },
    data() {
      return {
        isLoading: false,
        feedTypeList: []
      }
    },
    methods: {
      getFeedTypeList() {
        this.loading = true
        useLoadFeedTypes().then(response => {
          this.loading = false
          this.feedTypeList = response
        })
        .catch(error => {
          this.loading = false
          console.log(error)
        })
      }
    },
    created() {
      this.getFeedTypeList()
    }
  }
  </script>
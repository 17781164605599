<template>
    <NavHeader />
    <AdminUserCreate />
    <AdminUserList />
</template>
  
<script>
  import AdminUserCreate from '@/components/AdminUserCreate.vue'
  import AdminUserList from '@/components/AdminUserList.vue'
  import NavHeader from '@/components/NavHeader.vue'
  
  export default {
    
    name: 'Home',
    components: { NavHeader, AdminUserCreate, AdminUserList }
  }
</script>
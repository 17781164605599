<template>
    <NavHeader/>
    <AdminHome />
</template>
  
<script>
  import NavHeader from '@/components/NavHeader.vue'
  import AdminHome from '@/components/AdminHome.vue'
  
  export default {
    name: 'Home',
    components: { NavHeader, AdminHome }
  }
</script>
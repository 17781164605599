<template>
    <div class="card card-body mt-4">
      <h3>Edit Vertical</h3>
      <form @submit.prevent="update">
        
        <div class="form-group mt-3">
          <label>Title</label>
          <input v-model="form.title" class="form-control" required />
        </div>

        <div class="form-group">
          <label>Description</label>
          <input v-model="form.description" class="form-control" required />
        </div>

        <div class="form-group">
          <label>Minimum Child</label>
          <input type="number" v-model="form.min_child" class="form-control" required />
        </div>

        <div class="form-group">
          <label>Keywords</label>
          <textarea v-model="form.keywords" class="form-control"></textarea>
        </div>
        
        <div class="form-group">
          <label>Is Active</label>
          <input v-model="form.is_active" class="mx-2" type="checkbox" />
        </div>
  
        <button type="button" class="btn btn-danger mt-3" @click="cancelBtn">
          Cancel
        </button>

        <button type="submit" class="btn btn-primary mx-2 mt-3">
          Update
        </button>
      </form>
    </div>
  </template>
  
  <script>
  import { reactive, computed, onMounted } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import { getVertical, updateVertical } from '@/firebase'
  
  export default {
    setup() {
      const router = useRouter()
      const route = useRoute()
      const verticalId = computed(() => route.params.id)
  
      const form = reactive({ description: '', title: '', keywords: '', is_active: false, min_child: 0 })
      onMounted(async () => {
        const vertical = await getVertical(verticalId.value)
        console.log(vertical, vertical.value)
        form.description = vertical.description
        form.title = vertical.title
        form.keywords = vertical.keywords
        form.is_active = vertical.is_active
        form.min_child = vertical.min_child
      })
  
      const update = async () => {
        await updateVertical(verticalId.value, { ...form })
        router.push('/vertical')
        form.title = ''
        form.description = ''
        form.keywords = ''
        form.is_active = false
        form.min_child = 0
      }

      const cancelBtn = () => {
        router.push('/vertical')
      }
  
      return { form, update, cancelBtn }
    }
  }
  </script>
<template>
    <div class="card card-body mt-4">
      <h3>Edit Feed Type</h3>
      <form @submit.prevent="update">
        
        <div class="form-group mt-3">
          <label>Title</label>
          <input v-model="form.title" class="form-control" required />
        </div>

        <div class="form-group">
          <label>Description</label>
          <input v-model="form.description" class="form-control" required />
        </div>

        <div class="form-group">
          <label>Type Id</label>
          <input v-model="form.typeId" class="form-control" required />
        </div>
        
        <div class="form-group">
          <label>Api URL</label>
          <input v-model="form.apiurl" class="form-control" required />
        </div>

        <div class="form-group">
          <label>Api Key</label>
          <input v-model="form.apikey" class="form-control" required />
        </div>

        <div class="form-group">
          <label>Api Password</label>
          <input v-model="form.apipassword" class="form-control" required />
        </div>
  
        <button type="button" class="btn btn-danger mt-3" @click="cancelBtn">
          Cancel
        </button>
  
        <button type="submit" class="btn btn-primary mx-2 mt-3">
          Update
        </button>
      </form>
    </div>
  </template>
  
  <script>
  import { reactive, computed, onMounted } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import { getFeedType, updateFeedType } from '@/firebase'
  
  export default {
    setup() {
      const router = useRouter()
      const route = useRoute()
      const feedTypeId = computed(() => route.params.id)
  
      const form = reactive({ description: '', title: '', apiurl: '', apikey: '', apipassword: '' })

      onMounted(async () => {
        const feedType = await getFeedType(feedTypeId.value)

        form.description = feedType.description
        form.title = feedType.title
        form.apiurl = feedType.apiurl
        form.apikey = feedType.apikey
        form.apipassword = feedType.apipassword,
        form.typeId = feedType.typeId

      })
  
      const update = async () => {
        await updateFeedType(feedTypeId.value, { ...form })
        router.push('/feedtype')
        form.title = ''
        form.description = ''
        form.apiurl = ''
        form.apikey = ''
        form.apipassword = ''
        form.typeId = ''
      }

      const cancelBtn = () => {
        router.push('/feedtype')
      }
  
      return { form, update, cancelBtn }
    }
  }
  </script>
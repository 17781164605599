<template>
    <NavHeader />
    <FeedTypeCreate />
    <FeedTypeList />
</template>
  
<script>
  import FeedTypeCreate from '@/components/FeedTypeCreate.vue'
  import FeedTypeList from '@/components/FeedTypeList.vue'
  import NavHeader from '@/components/NavHeader.vue'

  export default {
    name: 'Home',
    components: { NavHeader, FeedTypeCreate, FeedTypeList }
  }
</script>
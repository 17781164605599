<template>
    <NavHeader />
    <VerticalList />
    <VerticalFeedCreate />
    <VerticalFeedList />
</template>
  
<script>
  import VerticalList from '@/components/VerticalList.vue'
  import VerticalFeedList from '@/components/VerticalFeedList.vue'
  import NavHeader from '@/components/NavHeader.vue'
  import VerticalFeedCreate from '@/components/VerticalFeedCreate.vue'

  export default {
    name: 'Home',
    components: { NavHeader, VerticalList, VerticalFeedList, VerticalFeedCreate }
  }
</script>
<template>
    <div class="card card-body mt-4">
        <router-link :to="`/vertical/add/`">
            <button class="btn btn-primary btn-sm me-2">
                Add New Vertical
            </button>
        </router-link>
    </div>
</template>


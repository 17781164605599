<template>
    <div class="card card-body mt-4">
      <h3>Add New Feed Type</h3>
      <form @submit.prevent="submit">
        <div class="form-group mt-3">
          <label>Title</label>
          <input
            v-model="form.title" class="form-control" required />
        </div>

        <div class="form-group">
          <label>Description</label>
          <input v-model="form.description" class="form-control" required />
        </div>

        <div class="form-group">
          <label>Type Id</label>
          <input v-model="form.typeId" class="form-control" required />
        </div>

        <div class="form-group mt-3">
          <label>Api URL</label>
          <input
            v-model="form.apiurl" class="form-control"  />
        </div>

        <div class="form-group mt-3">
          <label>Api Key</label>
          <input
            v-model="form.apikey" class="form-control"  />
        </div>

        <div class="form-group">
          <label>Api Password</label>
          <input v-model="form.apipassword" class="form-control" />
        </div>
  
        <button type="button" class="btn btn-danger mt-3" @click="cancelBtn">
          Cancel
        </button>

        <button type="submit" class="btn btn-primary mx-2 mt-3">
          Insert
        </button>

      </form>
    </div>
  </template>
  
  <script>
  import { reactive, computed, onMounted } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import { getFeedType, createFeedType } from '@/firebase'
  
  export default {
    setup() {
      const router = useRouter()
      const route = useRoute()
      
      const form = reactive({ description: '', title: '', typeId: '', apiurl: '', apikey: '', apipassword: '' })
        
      const submit = async () => {
        await createFeedType({ ...form})
        router.push('/feedtype')
        form.description = ''
        form.title = ''
        form.typeId = ''
        form.apiurl = ''
        form.apikey = ''
        form.apipassword = ''
      }

      const cancelBtn = () => {
        router.push('/feedtype')
      }
  
      return { form, submit, cancelBtn }
    }
  }
  </script>
<template>
    <div class="card mt-4">
      <table class="table m-0">
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Email</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="!loading"  v-for="{ id, name, email } in users" :key="id">
            <td>{{ name }}</td>
            <td>{{ email }}</td>
            <td>
              <router-link :to="`/adminuser/edit/${id}`">
                <button class="btn btn-primary btn-sm me-2">
                  Edit
                </button>
              </router-link>
              <button class="btn btn-danger btn-sm" @click="deleteAdmUserWrapper(id)">
                Delete
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
<script>
import { useLoadAdminUsers, deleteAdminUser } from '@/firebase'
export default {
  data() {
    return {
      loading: false,
      users: []
    }
  },
  created() {
    this.getAdminUserList()
  },
  methods: {
    getAdminUserList() {
      this.loading = true
      useLoadAdminUsers().then(response => {
        this.loading = false
        this.users = response
      })
      .catch(error => {
        this.loading = false
        console.log(error)
      })
    },
    deleteAdmUserWrapper(id){
        deleteAdminUser(id).then(()=>{
            this.getAdminUserList()
        })
    }
  }
}

</script>
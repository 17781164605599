<template>
    <NavHeader />
    <VerticalCreate />
    <VerticalList />
</template>
  
<script>
  import VerticalCreate from '@/components/VerticalCreate.vue'
  import VerticalList from '@/components/VerticalList.vue'
  import NavHeader from '@/components/NavHeader.vue'

  export default {
    name: 'Home',
    components: { NavHeader, VerticalCreate, VerticalList }
  }
</script>
<template>
    <div class="card card-body mt-4">
        <router-link :to="`/adminuser/add/`">
            <button class="btn btn-primary btn-sm me-2">
                Add New Admin User
            </button>
        </router-link>
    </div>
</template>


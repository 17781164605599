<template>
    <div class="card card-body mt-4">
        <router-link :to="`/vertical/${verticalId}/feed/add/`">
            <button class="btn btn-primary btn-sm me-2">
                Add New Vertical Feed
            </button>
        </router-link>
    </div>
</template>

<script>
import { useRoute } from 'vue-router'
export default {
  data() {
    return {
      loading: false,
      verticalId: null,
    }
  },
  created() {
    this.getVerticalId()
  },
  methods: {
    getVerticalId() {
        const route = useRoute()
        this.verticalId = route.params.verticalId
    }
  }
}

</script>

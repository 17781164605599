<template>
    <div class="card card-body mt-4">
      <h3>Edit users</h3>
      <form @submit.prevent="update">
        <div class="form-group">
          <label>Name</label>
          <input v-model="form.name" class="form-control" required />
        </div>
  
        <div class="form-group mt-3">
          <label>Email</label>
          <input
            v-model="form.email"
            class="form-control"
            type="email"
            required
          />
        </div>
  
        <button type="button" class="btn btn-danger mt-3" @click="cancelBtn">
          Cancel
        </button>
  
        <button type="submit" class="btn btn-primary mx-2 mt-3">
          Update
        </button>
      </form>
    </div>
  </template>
  
  <script>
  import { reactive, computed, onMounted } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import { getAdminUser, updateAdminUser } from '@/firebase'
  
  export default {
    setup() {
      const router = useRouter()
      const route = useRoute()
      const userId = computed(() => route.params.id)
  
      const form = reactive({ name: '', email: '' })
      onMounted(async () => {
        const user = await getAdminUser(userId.value)
        console.log(user, userId.value)
        form.name = user.name
        form.email = user.email
      })
  
      const update = async () => {
        await updateAdminUser(userId.value, { ...form })
        router.push('/adminuser')
        form.username = ''
        form.email = ''
      }

      const cancelBtn = () => {
        router.push('/adminuser')
      }
  
      return { form, update, cancelBtn }
    }
  }
  </script>
<template>
    <div class="card card-body mt-4">
      <h3>Admin Login</h3>
      <form @submit.prevent="login">
          
        <div class="form-group mt-3">
          <label>Email</label>
          <input v-model="form.email" class="form-control" type="email" required />
        </div>

        <div class="form-group mt-3">
          <label>Password</label>
          <input v-model="form.password" class="form-control" type="password" required />
        </div>
        <div v-if="form.loginError != ''" class="form-group">
          <br/>
          {{ form.loginError }}
          <br/>
        </div>
        <button type="button" class="btn btn-danger mt-3" @click="cancelBtn">
          Cancel
        </button>
  
        <button type="submit" class="btn btn-primary mx-2 mt-3">
          Login
        </button>
      </form>
    </div>
  </template>
  
  <script>
  import { reactive, computed } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import { loginAdminUser } from '@/firebase'
  
  export default {
    setup() {
      const router = useRouter()
      const route = useRoute()
      
      const form = reactive({ email: '', password: ''})
      
      const login = async () => {
        loginAdminUser(form.email, form.password).then(response => {
          console.log("Response: "+response.id)
          if (response.id == null){
            form.loginError = 'Invalid username or password!'
          }else{
            router.push('/')
            form.email = ''
            form.password = ''
            
          }
        })
      }

      const cancelBtn = () => {
        router.push('/')
      }
  
      return { form, login, cancelBtn }
    },
    data() {
      return {
        isLoading: false      }
    },
    methods: {
      
   },
    created() {
      
    }
  }
  </script>
<template>
    <div class="card mt-4">
      <table class="table m-0">
        <thead>
          <tr>
            <th scope="col">Title</th>
            <th scope="col">Name</th>
            <th scope="col">Description</th>
            <th scope="col">Publish Date</th>
            <th scope="col">Feed ID</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="!loading"  v-for="{ id, channelName, title, description, feedId, link, publishDate } in verticalContent" :key="id">
            <td><a v-bind:href="link" target="_blank">{{ title }}</a></td>
            <td>{{ channelName }}</td>
            <td>{{ description }}</td>
            <td>{{ publishDate }}</td>
            <td>{{ feedId }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
<script>
import { useLoadVerticalFeedContent } from '@/firebase'
import { reactive, computed, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
export default {
  data() {
    return {
      loading: false,
      verticalContent: []
    }
  },
  created() {
    this.getVerticalFeedContentList()
  },
  methods: {
    getVerticalFeedContentList() {
      const router = useRouter()
      const route = useRoute()
      const verticalFeedId = computed(() => route.params.id)
      const verticalId = computed(() => route.params.verticalId)

      this.loading = true
      useLoadVerticalFeedContent(verticalId.value, verticalFeedId.value).then(response => {
        this.loading = false
        this.verticalContent = response
      })
      .catch(error => {
        this.loading = false
        console.log(error)
      })
    }
  }
}

</script>
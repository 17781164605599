<template>
   <nav class="navbar navbar-expand-lg bg-body-tertiary">
      <div class="container-fluid" v-if="user != null">
          <a class="navbar-brand" href="/">Manage</a>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                  <li class="nav-item active">
                      <a class="nav-link" aria-current="page" href="/adminuser">Admin Users</a>
                  </li>
                  <li class="nav-item">
                      <a class="nav-link" href="/vertical">Verticals</a>
                  </li>
                  <li class="nav-item">
                      <a class="nav-link" href="/feedtype">Feed Types</a>
                  </li>
              </ul>
              
          </div>

          <div class="dropdown">
              <button class="btn btn-secondary dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
              >
                  {{ user.name }}
              </button>
              <ul class="dropdown-menu">
                  <li><a class="dropdown-item" @click="logout">Logout</a></li>
                  <li><a class="dropdown-item" href="/editself">Edit Self</a></li>
              </ul>
          </div>
      </div>
    </nav>
</template>

<script>
import { getAdminFromSession } from '@/firebase'
import { useRoute, useRouter } from 'vue-router'

export default {
  data() {
    return {
      loading: false,
      user: []
      
    }
  },
  created() {
    this.getAdminUser()
  },
  methods: {
    getAdminUser() {
        getAdminFromSession().then(response => {
        this.loading = false
        this.user = response

      })
    }
  },
  setup(){
    const router = useRouter()
    const route = useRoute()

    const logout = () => {
      console.log('logout called')
      document.cookie = "FPS-Admin" + '=' + "-1";
      router.push('/login')
    }

    return { logout}
  }

  
}
</script>
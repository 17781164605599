import { createRouter, createWebHistory } from 'vue-router'

import Home from '@/views/Home.vue'

import Login from '@/views/Login.vue'

import AdminUser from '@/views/AdminUser.vue'
import AddAdminUser from '@/views/AddAdminUser.vue'
import EditAdminUser from '@/views/EditAdminUser.vue'

import EditSelf from '@/views/EditSelf.vue'

import FeedType from '@/views/FeedType.vue'
import AddFeedType from '@/views/AddFeedType.vue'
import EditFeedType from '@/views/EditFeedType.vue'

import Vertical from '@/views/Vertical.vue'
import AddVertical from '@/views/AddVertical.vue'
import EditVertical from '@/views/EditVertical.vue'


import VerticalFeed from '@/views/VerticalFeed.vue'
import VerticalFeedContent from '@/views/VerticalFeedContent.vue'
import AddVerticalFeed from '@/views/AddVerticalFeed.vue'
import EditVerticalFeed from '@/views/EditVerticalFeed.vue'

import { getAdminFromSession } from '@/firebase'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/adminuser/',
        name: 'Admin User Home',
        component: AdminUser
    },
    {
        path: '/adminuser/add/',
        name: 'Add Admin User',
        component: AddAdminUser
    },
    {
        path: '/adminuser/edit/:id',
        name: 'Edit Admin User',
        component: EditAdminUser
    }, 
    {
        path: '/editself',
        name: 'Edit Self',
        component: EditSelf
    },
    {
        path: '/feedtype/',
        name: 'Feed Type Home',
        component: FeedType
    },
    {
        path: '/feedtype/add/',
        name: 'Add Feed Type',
        component: AddFeedType
    },
    {
        path: '/feedtype/edit/:id',
        name: 'Edit Feed Type',
        component: EditFeedType
    },
    {
        path: '/vertical/',
        name: 'Vertical Home',
        component: Vertical
    },
    {
        path: '/vertical/add/',
        name: 'Add Vertical',
        component: AddVertical
    },
    {
        path: '/vertical/edit/:id',
        name: 'Edit Vertical',
        component: EditVertical
    },
    {
        path: '/vertical/:verticalId/feed/',
        name: 'Vertical Feed Home',
        component: VerticalFeed
    },
    {
        path: '/vertical/:verticalId/feed/content/:id',
        name: 'Vertical Feed Content',
        component: VerticalFeedContent
    },
    {
        path: '/vertical/:verticalId/feed/add/',
        name: 'Add Vertical Feed',
        component: AddVerticalFeed
    },
    {
        path: '/vertical/:verticalId/feed/edit/:id',
        name: 'Edit Vertical Feed',
        component: EditVerticalFeed
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes

})

router.beforeEach((to, from, next) => {

    let keyValue = document.cookie.match('(^|;) ?' + "FPS-Admin" + '=([^;]*)(;|$)');
       
    console.log(keyValue)

    if (to.name !== 'Login' && (keyValue == null || keyValue[2] == null)){
        next({name: 'Login'})
    }else{
        next()
    }

    
})

export default router

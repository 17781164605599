<template>
    <div class="card mt-4">
      <table class="table m-0">
        <thead>
          <tr>
            <th scope="col">Title</th>
            <th scope="col">Description</th>
            <th scope="col">Authority</th>
            <th scope="col">Quality</th>
            <th scope="col">Type</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="!loading"  v-for="{ id, verticalId, title, authority, quality, description, type, url } in verticalfeeds" :key="id">
            <td><a v-bind:href="url" target="_blank">{{ title }}</a></td>
            <td>
              <router-link :to="`/vertical/${verticalId}/feed/content/${id}`">
                <button class="btn btn-primary btn-sm me-2">
                  {{ description }}
                </button>
              </router-link>
            </td>
            <td>{{ authority }}</td>
            <td>{{ quality }}</td>
            <td>{{ type }}</td>
            <td class="d-flex flex-row">
              <router-link :to="`/vertical/${verticalId}/feed/edit/${id}`">
                <button class="btn btn-primary btn-sm me-2">
                  Edit
                </button>
              </router-link>
              <button class="btn btn-danger btn-sm" @click="deleteVerticalFeedWrapper(verticalId, id)">
                Delete
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
<script>
import { useLoadVerticalFeeds, deleteVerticalFeed } from '@/firebase'
import { reactive, computed, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
export default {
  data() {
    return {
      loading: false,
      verticalsfeed: []
    }
  },
  created() {
    this.getVerticalFeedList()
  },
  methods: {
    getVerticalFeedList() {
      const router = useRouter()
      const route = useRoute()
      const verticalId = computed(() => route.params.verticalId)

      this.loading = true
      useLoadVerticalFeeds(verticalId.value).then(response => {
        this.loading = false
        this.verticalfeeds = response
      })
      .catch(error => {
        this.loading = false
        console.log(error)
      })
    },
    deleteVerticalFeedWrapper(verticalId, id){
        deleteVerticalFeed(verticalId, id).then(()=>{
          router.push('/vertical/'+verticalId.value+'/feed/')
        })
    }
  }
}

</script>
<template>
   <br/><br/>Welcome to the Front Page Systems - Admin Panels
   <span v-if="user == null">
      <br/><br/>
      <a href="/login">Click here</a> to login.
   </span>
</template>

<script>
import { getAdminFromSession } from '@/firebase'
import { useRoute, useRouter } from 'vue-router'

export default {
  data() {
    return {
      loading: false,
      user: []
    }
  },
  created() {
    this.getAdminUser()
  },
  methods: {
    getAdminUser() {
        getAdminFromSession().then(response => {
        this.loading = false
        this.user = response

      })
    }
  },
  setup(){
   const router = useRouter()
   const route = useRoute()

   return {}
  }

  
}
</script>